import { motion } from "framer-motion";
import '../styles/header.css';
import { useState } from 'react';
import { navVariants } from "../utils/motion";


const Header = () => {

  const [navbar, setNavbar ] = useState(false)
    return (
  
        <header className="header">
          <motion.nav
            variants={navVariants}
            initial = "hidden"
            whileInView="show"
            viewport={{ once : true, amount: 0.25 }}
            className= 'bg-black relative '
          >
         <div className="justify-between px-4 mx-auto lg:max-w-7xl  md:px-8">
         {/* <div className="justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8"> */}
            <div>
              <div className="flex items-center justify-between py-1 md:py-4 flex-1">
                <div className="">
                  <a href="#home">
                    <img src="/assets/images/logo3.jpeg" alt="logo" className=" h-[56px] rounded-[8px] object-contain"/>
                  </a>
    
                </div>
                  <div className="">
                  <button
                    className="p-2 text-gray-700 rounded-md outline-none" onClick={() => setNavbar(!navbar)}>
                    {navbar ? (
                      <img src="/assets/icons/close-icon.svg" className="opacity-100" width={60} height={60} style={{ marginRight: 0}} alt="close" />
                    ) : (
                      <img src="/assets/icons/menu2.svg" width={60} height={60} style={{ marginRight: 0}} alt="menu" />
                      // <picture  className="hamburgerMenu focus:border-none active:border-none" >
                      //   <source type="image/webp" srcSet="/assets/images/papparotibestfoodnairobi.webp" className="hamburgerMenu focus:border-none active:border-none"/> 
                      //   <source type="image/jpeg" srcSet="/assets/images/papparotibestfoodnairobi.png"  className="hamburgerMenu focus:border-none active:border-none" /> 
                      //   <img src="/assets/images/papparotibestfoodnairobi.png" alt="menu"  className="hamburgerMenu focus:border-none active:border-none" /> 
                      // </picture>
                     
                    )}
                  </button>
                </div>
              </div>
            </div>
            <div>
              <div
                className={`flex-1 justify-self-center pb-2 mt-1 md:block md:pb-0 md:mt-0 
                ${
                  navbar ? 'p-4 md:p-0 block' : 
                  'hidden'
                }`
              }
              >
                {/* <div className="rounded-b overflow-hidden" > */}
                <ul className={`h-auto opacity-80 items-center justify-center 
                    ${
                      navbar ? 'p-4 md:p-0 block' : 
                      'hidden'
                    }
                `}>
                {/* <ul className="h-auto opacity-70  items-center justify-center md:h-auto  md:flex "> */}
                  <li className="pb-4 text-l  text-[#fff] py-2 px-6 text-center border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090]  md:hover:text-[#fff] md:hover:bg-transparent">
                  

                    <a href="#about" onClick={() => setNavbar(!navbar)}>
                      THE BRAND
                    </a>
                    <div className="flex flex-1 justify-between">
                      <div className="w[1px]" />
                      <div className=" justify-center border-b-2 opacity-50 mt-4 border-b-white w-[230px]" />
                      <div className="w[1px]" />
                   </div>
                    {/* </div> */}
                  </li>
                  <li className="pb-4 text-l text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090]  md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="#our-menu" onClick={() => setNavbar(!navbar)}>
                      OUR MENU                
                    </a>
                    <div className="flex flex-1 justify-between">
                      <div className="w[1px]" />
                      <div className=" justify-center border-b-2 opacity-50 mt-4 border-b-white w-[230px]" />
                      <div className="w[1px]" />
                   </div>
                  </li>
                  <li className="pb-4 text-l text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090]  md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="#whats-new" onClick={() => setNavbar(!navbar)}>
                      WHAT'S NEW
                    </a>
                    <div className="flex flex-1 justify-between">
                      <div className="w[1px]" />
                      <div className=" justify-center border-b-2 opacity-50 mt-4 border-b-white w-[230px]" />
                      <div className="w[1px]" />
                   </div>
                  </li>
                  {/* <li className="pb-4 text-l text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090]  md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="mailto:papparotike@gmail.com" onClick={() => setNavbar(!navbar)}>
                      FRANCHISE
                    </a>
                    <div className="flex flex-1 justify-between">
                      <div className="w[1px]" />
                      <div className=" justify-center border-b-2 opacity-50 mt-4 border-b-white w-[230px]" />
                      <div className="w[1px]" />
                   </div>
                  </li> */}
                  <li className="pb-4 text-l text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090]  md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="#order" onClick={() => setNavbar(!navbar)}>
                      FIND US
                    </a>
                    <div className="flex flex-1 justify-between">
                      <div className="w[1px]" />
                      <div className=" justify-center border-b-2 opacity-50 mt-4 border-b-white w-[230px]" />
                      <div className="w[1px]" />
                   </div>
                  </li>
                  {/* <li className="pb-4 text-l text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090] md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="#order" onClick={() => setNavbar(!navbar)}>
                      ORDER
                    </a>
                    <div className="flex flex-1 justify-between">
                      <div className="w[1px]" />
                      <div className=" justify-center border-b-2 opacity-50 mt-4 border-b-white w-[230px]" />
                      <div className="w[1px]" />
                   </div>
                  </li> */}
                  <li className="pb-4 text-l text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-[#909090]  border-[#909090] md:hover:text-[#fff] md:hover:bg-transparent">
                    <a href="https://www.instagram.com/papparoti_uganda/" target="_blank" onClick={() => setNavbar(!navbar)} rel="noreferrer">
                      BE SOCIAL
                    </a>
                  </li>
                  <li className="pb-20 text-l text-[#fff] py-2 px-6 text-center  border-b-1 md:border-b-0  hover:bg-transparent  border-[#909090] md:hover:text-[#fff] md:hover:bg-transparent">
                   <div className="flex justify-center items-center">
                   <div className="flex  gap-6  flex-row">
                    <a href="https://www.instagram.com/papparoti_uganda/" target="_blank" rel="noreferrer">
                      <img className="h-6" src="/assets/icons/ig.png" alt="" />
                    </a>
                    {/* <a href="https://www.facebook.com/profile.php?id=100092510330767" target="_blank" rel="noreferrer">
                      <img className="h-5 opacity-80" src="/assets/icons/fb2.png" alt="" />
                    </a> */}
                    <a href="https://www.youtube.com/@papparotiuae2643" target="_blank" rel="noreferrer">
                      <img className="h-5" src="/assets/icons/youtube.png" alt="" />
                      </a>
                    <a href="tel:+256754444063" target="_blank" rel="noreferrer">
                      <img className="h-5" src="/assets/icons/call.png" alt="Pappa Roti Kampala Contacts " />
                      </a>
                    <a href="https://maps.app.goo.gl/KiK9uvRP9W1qhVhJA" target="_blank" rel="noreferrer">
                      <img className="h-5" src="/assets/icons/map-pin.svg" alt="Pappa Roti Kampala Directions" />
                      </a>
                    </div>
                   </div>
                  </li>
                </ul>
                </div>
              {/* </div> */}
            </div>
          </div>
       </motion.nav>

</header>
  )
      }
//   )
// }
export default Header;
